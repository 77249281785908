<template>
  <div>
    <header class="header-row grid-2 mt-2">
      <div class="grid-2-left" style="border-top:0px;padding-top:5px;">
        輔導簡述(問題原因、輔導摘要、處理方式、建議事項)
      </div>
      <div></div>
      <b-textarea
        v-model="modalItem.Remark1"
        style="grid-column: 1/-1;"
        rows="4"
      ></b-textarea>
    </header>
    <div class="grid-one mt-2">
      <b-input-group prepend="輔導老師">
        <b-input v-model="modalItem.TeacherName" disabled></b-input>
      </b-input-group>
    </div>
    <div style="text-align: end;">
      <b-button class="mt-2 mr-2 btn-save" variant="success" @click="saveRecord"
        >儲存</b-button
      >

      <b-button class="mt-2" variant="info" @click="closeAll">取消</b-button>
    </div>
  </div>
</template>
<script>
import { store } from "@/store/global.js";

export default {
  data() {
    return {
      telphone: "",
      tutorDate: null,
      tutorPlace: "",
      studentClass: "",
      studentId: "",

      remark1: "",
      remark2: "",
      allArray: [],
      allObject: {},

      modalItem: {
        Identifier: "",
        Remark1: "",
        StudentName: "",
        TeacherName: "",
        Tutor_times: "4",
      },

      allItems: {},
    };
  },

  props: ["recordAItems", "personinfo", "isAlreadyExist"],
  methods: {
    closeAll() {
      this.$emit("close");
    },
    setData() {
      let obj = JSON.parse(JSON.stringify(this.allItems));
      obj.TeacherName = `${window.sessionStorage.fullname}`.replace(/\"/g, "");
      this.modalItem = JSON.parse(JSON.stringify(obj));
    },
    async saveRecord() {
      let obj = JSON.parse(JSON.stringify(this.modalItem));

      try {
        const urlPut = `RecordAll/PutSingle`;
        const urlPost = `RecordAll/PostSingle`;
        this.isAlreadyExist
          ? await window.axios.put(urlPut, obj)
          : await window.axios.post(urlPost, obj);

        // this.$bvToast.toast(`總輔導簡述儲存成功`, {
        //   title: "系統資訊",
        //   autoHideDelay: 5000,
        //   variant: "success",
        // });
        this.$emit("updateModal2");
      } catch (error) {
        this.$bvToast.toast(error, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
  },
  watch: {},
  mounted() {
    if (this.recordAItems && Boolean(this.recordAItems.Identifier)) {
      this.allItems = JSON.parse(JSON.stringify(this.recordAItems));
      this.setData();
    } else {
      this.allItems = {
        Identifier: this.personinfo.Identifier,
        Remark1: "",
        StudentName: this.personinfo.StudentName,
        TeacherName: "",
        Tutor_times: "4",
      };

      this.setData();
    }
  },
};
</script>
<style lang="scss" scoped>
.header-row {
  font-size: 18px;
  background: #c0c0c045;
  color: #333;
  border-radius: 3px;
  padding-left: 1px;
  padding-bottom: 10px;
  display: grid;
  grid-template-columns: repeat(24, max-content);
  grid-gap: 6px;
  border: #a4a7a7 1px solid;

  button {
    max-height: 35px;
    margin-top: 3px;
    line-height: 12px;
  }
}
.grid-2 {
  display: grid;
  grid-template-columns: 600px 422px;
  padding-left: 8px;
  .grid-content-2 {
    display: grid;
    grid-template-columns: max-content 185px;
    grid-column-gap: 7px;
  }
}

.grid-one {
  display: grid;
  grid-template-columns: 240px 270px 175px 175px 175px;
  grid-column-gap: 6px;
}
.input-group-text {
  // width: 100px;
  background: #0379fd;
  color: #fff;
}
</style>
