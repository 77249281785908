<template>
  <section class="dtc-main-section">
    <vue-tabs v-model="tabName">
      <v-tab
        v-for="(item, i) in titles"
        :title="item.title"
        :key="item.comps + i"
      >
        <component
          v-if="tabName === item.title"
          :is="item.comps"
          :tabName="item.comps"
        />
      </v-tab>
    </vue-tabs>
  </section>
</template>

<script>
import { store } from "@/store/global.js";
import { VueTabs, VTab } from "vue-nav-tabs";
import "vue-nav-tabs/themes/vue-tabs.css";
import NurseRecord from "./NurseRecord";

export default {
  name: "LocalCourseManageTab",
  data() {
    return {
      showTab1: false,
      show: true,
      tabName: "",
      allUserRight: [],
      userRights: [],
      mainTitle: "「課程管理」",
      titles: [
        {
          title: "偏護菁英-輔導紀錄",
          comps: "NurseRecord",
          isActive: false,
          functionId: "70020",
        },
      ],
    };
  },
  computed: {},
  components: {
    VueTabs,
    VTab,
    NurseRecord,
  },
  methods: {
    setUserRight() {
      let arr = Boolean(sessionStorage.functionIds)
        ? JSON.parse(sessionStorage.getItem("functionIds"))
        : [];
      this.titles = this.titles.map((s) => {
        s.isActive = arr.find((k) => k == s.functionId) ? true : false;
        return s;
      });
      this.titles = this.titles.filter((s) => s.isActive);
    },
  },
  created() {},
  beforeMount() {},
  mounted() {
    // this.setUserRight();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.dtc-main-section {
  width: fit-content;
  //   height: 100vh;
  //   overflow-x: hidden;
}
iframe {
  width: 100vw;
  height: clamp(60vh, 90vh, 100vh);
}
</style>
