<template>
  <b-modal
    @shown="updateItem"
    :title="
      `${schoolName} ${this.year}年度 第${
        this.term == 1 ? '一' : '二'
      }學期 總輔導記錄維護`
    "
    id="schoolRecordallMaintain"
    size="lg"
  >
    <b-container fluid>
      <header class="header-row grid-2 mt-2">
        <div class="grid-2-left" style="border-top:0px;padding-top:5px;">
          公費生輔導問題綜總與建議
        </div>
        <div></div>
        <b-textarea
          v-model="modalItem.Recommend"
          style="grid-column: 1/-1;"
          rows="4"
        ></b-textarea>
      </header>
    </b-container>

    <template v-slot:modal-footer>
      <div class="w-100">
        <b-button
          variant="info"
          class="float-right  mr-3"
          @click="$bvModal.hide('schoolRecordallMaintain')"
          >取消</b-button
        >
        <b-button variant="success" class="float-right  mr-2" @click="saveInfo"
          >確定</b-button
        >
        <b-button variant="danger" class="float-right  mr-2" @click="printPage"
          >列印</b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
import { store } from "@/store/global.js";
import buildQuery from "odata-query";
const rows = [10, 20, 50];
export default {
  name: "schoolRecordallMaintain",
  data() {
    return {
      personinfo: {},
      variants: [
        "warning",
        "dark",
        "light",
        "warning",
        "danger",
        "info",
        "light",
        "dark",
      ],

      modalItem: {},
      isNew: false,
    };
  },
  computed: {
    year() {
      return store.editRecord.year;
    },
    term() {
      return store.editRecord.term;
    },
    schoolName() {
      return store.editRecord.schoolName;
    },
  },
  methods: {
    async updateItem() {
      this.isNew = true;
      this.modalItem = {};
      await this.getData();
    },
    async saveInfo() {
      const urlPut = `RecordAll/Put`;
      const urlPost = `RecordAll/Post`;
      const obj = {
        ...this.modalItem,
      };
      delete obj.nurse_recordall;
      try {
        this.isNew
          ? await window.axios.put(urlPost, obj)
          : await window.axios.put(urlPut, obj);
        this.$bvToast.toast(`總輔導記錄儲存成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });

        this.$bvModal.hide("schoolRecordallMaintain");
      } catch (e) {
        this.$bvToast.toast(`${e.response ? e.response.data : e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async getData() {
      let obj = { ...store.editRecord };

      const url = `RecordAll/Get?schoolcode=${obj.school}&byear=${obj.year}&term=${obj.term}`;

      try {
        let res = await window.axios.get(url);
        this.isNew = res.length ? false : true;
        res = res.length
          ? res[0]
          : {
              CreateName: `${window.sessionStorage.fullname}`.replace(
                /\"/g,
                ""
              ),
              Recommend: "",
              Ryear: "110",
              SchoolCode: obj.school,
              Term: +obj.term,
            };
        res.Term = +obj.term;
        this.modalItem = JSON.parse(JSON.stringify(res));
      } catch (e) {
        console.log(e);
      }
    },
    printPage() {
      const url = `/#/doc17`;

      window.open(url);
    },
  },

  mounted() {},
  watch: {
    currentPageNum(v) {
      this.currentPageNum = v;
      this.getData().catch((e) => {});
    },
    pagingRowPerPage(v) {
      this.getData().catch((e) => {});
    },
  },
};
</script>

<style scoped lang="scss">
.dtc-grid-header,
.dtc-grid-header2 {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: column;
  text-align: center;
  border-right: 0px;

  > div {
    // word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    word-break: keep-all;
    -webkit-box-orient: vertical;
    font-size: 14px;
    padding: 4px 0;
    border-top: 1px solid #dee2e5;
    border-right: 1px solid #dee2e5;
    border-bottom: 1px solid #dee2e5;
  }
  > div:first-child {
    border-left: 1px solid #dee2e5;
  }
  > div:last-child {
    // border-right: none;
  }
}
.dtc-grid-header2 {
  > div {
    font-size: 16px;
    padding: 0;
    line-height: 46px;
    height: 46px;
  }
  .fee {
    text-align: right;
    padding-right: 10px;
  }
}
p {
  font-size: 2em;
  text-align: center;
}
.input-group-text {
  width: 100px;
  background: #0379fd;
  color: #fff;
}

div.input-group.special-col > div.input-group-prepend > div.input-group-text {
  width: 130px;
}
div.input-group.dtc-bg > div.input-group-prepend > div.input-group-text {
  background: #646969;
  color: #ffffff;
}

.input-group {
  margin-bottom: 10px;
}

::v-deep
  #schoolRecordallMaintain
  > .modal-dialog
  > .modal-content
  > .modal-header {
  background: #7e2ca3;
  color: #ffffff;
  .close {
    color: #ffffff;
  }
}
// ::v-deep .modal-content {
//   margin-left: -180px;
//   width: 136%;
// }
.form-control[readonly] {
  background-color: #ffffff;
}
hr.new {
  border-top: 1px dashed #cacaca;
}
h4.title {
  font-size: 20px;
  font-weight: 700;
}
.dtx-footer {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  grid-gap: 12px;
}
.header-row {
  font-size: 18px;
  background: #c0c0c045;
  color: #333;
  border-radius: 3px;

  border: #a4a7a7 1px solid;
  padding: 0 7px 10px;
  button {
    max-height: 35px;
    margin-top: 3px;
    line-height: 12px;
  }
}
</style>
