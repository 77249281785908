<template>
  <div>
    <div class="basic-info">
      <b-input-group prepend="輔導日期">
        <date-picker-tw
          :time="tutorDate"
          @update="(t) => (tutorDate = t)"
        ></date-picker-tw>
      </b-input-group>
      <b-input-group prepend="輔導形式/地點">
        <b-input
          v-model="tutorPlace"
          placeholder="10字"
          maxlength="10"
        ></b-input>
      </b-input-group>
      <b-input-group prepend="班級">
        <b-input
          v-model="studentClass"
          placeholder="5字"
          maxlength="5"
        ></b-input>
      </b-input-group>
      <b-input-group prepend="學號">
        <b-input
          v-model="studentId"
          placeholder="10字"
          maxlength="10"
        ></b-input>
      </b-input-group>
      <b-input-group prepend="電話">
        <b-input v-model="telphone" placeholder="10字" maxlength="11"></b-input>
      </b-input-group>
    </div>
    <header class="header-row grid-2 mt-2">
      <div class="grid-2-left" style="border-top:0px;padding-top:5px;">
        一、輔導原因:
      </div>
      <div></div>
      <b-form-checkbox v-model="Question_01.q1" value="Y" unchecked-value="N">
        大一新生入學輔導
      </b-form-checkbox>
      <b-form-checkbox v-model="Question_01.q2" value="Y" unchecked-value="N">
        選課、一般課程輔導
      </b-form-checkbox>
      <b-form-checkbox v-model="Question_01.q3" value="Y" unchecked-value="N">
        生活輔導(生涯規劃、轉系諮詢、人際關係、經濟、家庭、感情、情緒等)
      </b-form-checkbox>
      <b-form-checkbox v-model="Question_01.q4" value="Y" unchecked-value="N">
        學習狀況不佳 (缺曠、上課不專注、成績不佳等)
      </b-form-checkbox>
      <b-form-checkbox v-model="Question_01.q5" value="Y" unchecked-value="N">
        期中預警
      </b-form-checkbox>
      <b-form-checkbox v-model="Question_01.q6" value="Y" unchecked-value="N">
        上學期二分之一以上學分不及格
      </b-form-checkbox>
      <b-form-checkbox v-model="Question_01.q7" value="Y" unchecked-value="N">
        一般訪談
      </b-form-checkbox>
      <div class="grid-content-2">
        <b-form-checkbox
          v-model="Question_01.q8"
          value="Y"
          unchecked-value="N"
          @change="clearData(Question_01.q8, 'Question_01Event')"
        >
          緊急事件處理，事件 :
        </b-form-checkbox>
        <b-input
          :disabled="Question_01.q8 == 'N'"
          v-model="Question_01.event"
          placeholder="最多10個字"
          maxlength="10"
        ></b-input>
      </div>
      <div class="grid-content-2">
        <b-form-checkbox
          v-model="Question_01.q9"
          value="Y"
          @change="clearData(Question_01.q9, 'Question_01Other')"
          unchecked-value="N"
        >
          其他 :
        </b-form-checkbox>
        <b-input
          :disabled="Question_01.q9 == 'N'"
          v-model="Question_01.other"
          placeholder="最多10個字"
          maxlength="10"
        ></b-input>
      </div>
    </header>
    <header class="header-row grid-3 mt-2 pt-2">
      <div>
        二、學生狀況評估:
      </div>
      <div></div>
      <div></div>
      <div class="question-font">1.家庭狀況:</div>
      <b-form-checkbox v-model="Question_021.q1" value="Y" unchecked-value="N">
        雙親家庭
      </b-form-checkbox>
      <b-form-checkbox v-model="Question_021.q2" value="Y" unchecked-value="N">
        單親家庭
      </b-form-checkbox>
      <b-form-checkbox v-model="Question_021.q3" value="Y" unchecked-value="N">
        隔代教養家庭
      </b-form-checkbox>
      <b-form-checkbox v-model="Question_021.q4" value="Y" unchecked-value="N">
        失親
      </b-form-checkbox>
      <b-form-checkbox v-model="Question_021.q5" value="Y" unchecked-value="N">
        依親
      </b-form-checkbox>
      <b-form-checkbox v-model="Question_021.q6" value="Y" unchecked-value="N">
        自己外居
      </b-form-checkbox>
      <b-form-checkbox v-model="Question_021.q7" value="Y" unchecked-value="N">
        僅與兄弟姊妹同住
      </b-form-checkbox>
      <div class="grid-content-2">
        <b-form-checkbox
          v-model="Question_021.q8"
          value="Y"
          unchecked-value="N"
          @change="clearData(Question_021.q8, 'Question_021Other')"
        >
          其他 :
        </b-form-checkbox>
        <b-input
          :disabled="Question_021.q8 == 'N'"
          v-model="Question_021.other"
          placeholder="最多10個字"
          maxlength="10"
        ></b-input>
      </div>

      <div class=" question-font">2.家庭經濟:</div>
      <b-form-checkbox
        v-model="Question_022.q1"
        value="Y"
        unchecked-value="N"
        class=""
      >
        家庭經濟富裕
      </b-form-checkbox>
      <b-form-checkbox
        v-model="Question_022.q2"
        value="Y"
        unchecked-value="N"
        class=""
      >
        家庭經濟小康
      </b-form-checkbox>
      <b-form-checkbox v-model="Question_022.q3" value="Y" unchecked-value="N">
        家庭經濟普通
      </b-form-checkbox>
      <b-form-checkbox v-model="Question_022.q4" value="Y" unchecked-value="N">
        家庭經濟不佳
      </b-form-checkbox>
      <div class="grid-content-2">
        <b-form-checkbox
          v-model="Question_022.q5"
          value="Y"
          unchecked-value="N"
          @change="clearData(Question_022.q5, 'Question_022Other')"
        >
          其他 :
        </b-form-checkbox>
        <b-input
          v-model="Question_022.other"
          placeholder="最多10個字"
          maxlength="10"
          :disabled="Question_022.q5 == 'N'"
        ></b-input>
      </div>

      <div class=" question-font">3.家庭互動:</div>
      <b-form-checkbox
        v-model="Question_023.q1"
        value="Y"
        unchecked-value="N"
        class=""
      >
        家庭互動良好
      </b-form-checkbox>
      <b-form-checkbox
        v-model="Question_023.q2"
        value="Y"
        unchecked-value="N"
        class=""
      >
        家庭互動尚可
      </b-form-checkbox>
      <b-form-checkbox v-model="Question_023.q3" value="Y" unchecked-value="N">
        家庭互動不佳
      </b-form-checkbox>
      <div class="grid-content-2">
        <b-form-checkbox
          v-model="Question_023.q4"
          value="Y"
          unchecked-value="N"
          @change="clearData(Question_023.q4, 'Question_023Other')"
        >
          其他 :
        </b-form-checkbox>
        <b-input
          :disabled="Question_023.q4 == 'N'"
          v-model="Question_023.other"
          placeholder="最多10個字"
          maxlength="10"
        ></b-input>
      </div>
      <div></div>

      <div class=" question-font">4.學習志趣:</div>
      <b-form-checkbox
        v-model="Question_024.q1"
        value="Y"
        unchecked-value="N"
        class="one-row"
      >
        目前系（所）合乎自己的志趣
      </b-form-checkbox>
      <b-form-checkbox
        v-model="Question_024.q2"
        value="Y"
        unchecked-value="N"
        class="one-row"
      >
        目前系（所）不合乎自己的志趣，但會完成它
      </b-form-checkbox>
      <b-form-checkbox
        v-model="Question_024.q3"
        value="Y"
        unchecked-value="N"
        class="one-row"
      >
        目前系（所）不是自己的志趣，所以打算轉換系（所）
      </b-form-checkbox>
      <div class="grid-content-2 one-row">
        <b-form-checkbox
          v-model="Question_024.q4"
          value="Y"
          unchecked-value="N"
          @change="clearData(Question_024.q4, 'Question_024Other')"
        >
          其他 :
        </b-form-checkbox>
        <b-input
          v-model="Question_024.other"
          placeholder="最多10個字"
          maxlength="10"
          :disabled="Question_024.q4 == 'N'"
        ></b-input>
      </div>
      <div></div>
      <div class=" question-font">5.學習狀況:</div>
      <b-form-checkbox
        v-model="Question_025.q1"
        value="Y"
        unchecked-value="N"
        class=""
      >
        學習狀況良好
      </b-form-checkbox>
      <b-form-checkbox
        v-model="Question_025.q2"
        value="Y"
        unchecked-value="N"
        class=""
      >
        學習狀況尚可
      </b-form-checkbox>
      <b-form-checkbox v-model="Question_025.q3" value="Y" unchecked-value="N">
        學習狀況不佳
      </b-form-checkbox>
      <b-form-checkbox v-model="Question_025.q4" value="Y" unchecked-value="N">
        預警輔導未改善
      </b-form-checkbox>
      <div class="grid-content-2">
        <b-form-checkbox
          v-model="Question_025.q5"
          value="Y"
          unchecked-value="N"
          @change="clearData(Question_025.q5, 'Question_025Other')"
        >
          其他 :
        </b-form-checkbox>
        <b-input
          v-model="Question_025.other"
          placeholder="最多10個字"
          maxlength="10"
          :disabled="Question_025.q5 == 'N'"
        ></b-input>
      </div>

      <div class=" question-font">6.生涯規劃:</div>
      <b-form-checkbox
        v-model="Question_026.q1"
        value="Y"
        unchecked-value="N"
        class=""
      >
        對未來已有計畫
      </b-form-checkbox>
      <b-form-checkbox
        v-model="Question_026.q2"
        value="Y"
        unchecked-value="N"
        class=""
      >
        順其自然
      </b-form-checkbox>
      <b-form-checkbox v-model="Question_026.q3" value="Y" unchecked-value="N">
        對未來感到茫然
      </b-form-checkbox>
      <div class="grid-content-2">
        <b-form-checkbox
          v-model="Question_026.q4"
          value="Y"
          unchecked-value="N"
          @change="clearData(Question_026.q4, 'Question_026Other')"
        >
          其他 :
        </b-form-checkbox>
        <b-input
          v-model="Question_026.other"
          placeholder="最多10個字"
          maxlength="10"
          :disabled="Question_026.q4 == 'N'"
        ></b-input>
      </div>
      <div></div>

      <div class=" question-font">7.生活適應:</div>
      <b-form-checkbox
        v-model="Question_027.q1"
        value="Y"
        unchecked-value="N"
        class=""
      >
        適應良好
      </b-form-checkbox>
      <b-form-checkbox
        v-model="Question_027.q2"
        value="Y"
        unchecked-value="N"
        class=""
      >
        尚可
      </b-form-checkbox>
      <div class="grid-content-2 ">
        <b-form-checkbox
          v-model="Question_027.q3"
          value="Y"
          unchecked-value="N"
          @change="clearData(Question_027.q3, 'Q27Remark')"
        >
          適應不良，問題 :
        </b-form-checkbox>
        <b-input
          v-model="Question_027.remark"
          placeholder="最多10個字"
          maxlength="10"
          :disabled="Question_027.q3 == 'N'"
        ></b-input>
      </div>
      <div class="grid-content-2">
        <b-form-checkbox
          v-model="Question_027.q4"
          value="Y"
          unchecked-value="N"
          @change="clearData(Question_027.q4, 'Question_027Other')"
        >
          其他 :
        </b-form-checkbox>
        <b-input
          :disabled="Question_027.q4 == 'N'"
          v-model="Question_027.other"
          placeholder="最多10個字"
          maxlength="10"
        ></b-input>
      </div>

      <div class=" question-font">8.人際關係:</div>
      <b-form-checkbox
        v-model="Question_028.q1"
        value="Y"
        unchecked-value="N"
        class=""
      >
        與同儕互動佳
      </b-form-checkbox>
      <b-form-checkbox
        v-model="Question_028.q2"
        value="Y"
        unchecked-value="N"
        class=""
      >
        尚可
      </b-form-checkbox>
      <div class="grid-content-2" style="grid-column: span 2;">
        <b-form-checkbox
          v-model="Question_028.q3"
          value="Y"
          unchecked-value="N"
          @change="clearData(Question_028.q3, 'Q28Remark')"
        >
          與同儕互動不佳，原因 :
        </b-form-checkbox>
        <b-input
          v-model="Question_028.remark"
          placeholder="最多10個字"
          maxlength="10"
          :disabled="Question_028.q3 == 'N'"
        ></b-input>
      </div>
      <div class="grid-content-2">
        <b-form-checkbox
          v-model="Question_028.q4"
          value="Y"
          unchecked-value="N"
          @change="clearData(Question_028.q4, 'Question_028Other')"
        >
          其他 :
        </b-form-checkbox>
        <b-input
          v-model="Question_028.other"
          placeholder="最多10個字"
          maxlength="10"
          :disabled="Question_028.q4 == 'N'"
        ></b-input>
      </div>

      <div class=" question-font">9.身體健康:</div>
      <b-form-checkbox
        v-model="Question_029.q1"
        value="Y"
        unchecked-value="N"
        class=""
      >
        健康良好
      </b-form-checkbox>
      <b-form-checkbox
        v-model="Question_029.q2"
        value="Y"
        unchecked-value="N"
        class=""
      >
        尚可
      </b-form-checkbox>
      <b-form-checkbox v-model="Question_029.q3" value="Y" unchecked-value="N">
        健康狀況不佳
      </b-form-checkbox>

      <div class="grid-content-2">
        <b-form-checkbox
          v-model="Question_029.q4"
          value="Y"
          unchecked-value="N"
          @change="clearData(Question_029.q4, 'Question_029Health')"
        >
          身體疾病 :
        </b-form-checkbox>
        <b-input
          v-model="Question_029.health"
          placeholder="最多10個字"
          maxlength="10"
          :disabled="Question_029.q4 == 'N'"
        ></b-input>
      </div>
      <b-form-checkbox v-model="Question_029.q5" value="Y" unchecked-value="N">
        領有身心障礙手冊
      </b-form-checkbox>
      <div class="grid-content-2">
        <b-form-checkbox
          v-model="Question_029.q6"
          value="Y"
          unchecked-value="N"
          @change="clearData(Question_029.q6, 'Question_029Other')"
        >
          其他 :
        </b-form-checkbox>
        <b-input
          :disabled="Question_029.q6 == 'N'"
          v-model="Question_029.other"
          placeholder="最多10個字"
          maxlength="10"
        ></b-input>
      </div>
      <div></div>
      <div></div>

      <div class=" question-font">10.心理健康:</div>
      <b-form-checkbox
        v-model="Question_0210.q1"
        value="Y"
        unchecked-value="N"
        class=""
      >
        樂觀開朗
      </b-form-checkbox>
      <b-form-checkbox
        v-model="Question_0210.q2"
        value="Y"
        unchecked-value="N"
        class=""
      >
        尚可
      </b-form-checkbox>
      <b-form-checkbox v-model="Question_0210.q3" value="Y" unchecked-value="N">
        經常情緒低落
      </b-form-checkbox>
      <b-form-checkbox v-model="Question_0210.q4" value="Y" unchecked-value="N">
        情緒起伏很大
      </b-form-checkbox>
      <div class="grid-content-2">
        <b-form-checkbox
          v-model="Question_0210.q5"
          value="Y"
          unchecked-value="N"
          @change="clearData(Question_0210.q5, 'Question_0210Health')"
        >
          精神疾病 :
        </b-form-checkbox>
        <b-input
          v-model="Question_0210.health"
          placeholder="最多10個字"
          maxlength="10"
          :disabled="Question_0210.q5 == 'N'"
        ></b-input>
      </div>

      <div class="grid-content-2">
        <b-form-checkbox
          v-model="Question_0210.q6"
          value="Y"
          unchecked-value="N"
          @change="clearData(Question_0210.q6, 'Question_0210Other')"
        >
          其他 :
        </b-form-checkbox>
        <b-input
          :disabled="Question_0210.q6 == 'N'"
          v-model="Question_0210.other"
          placeholder="最多10個字"
          maxlength="10"
        ></b-input>
      </div>
    </header>

    <header class="header-row grid-2 mt-3">
      <div class="grid-2-left" style="border-top:0px;padding-top:5px;">
        問題原因、輔導摘要(必填)
      </div>
      <div></div>
      <b-textarea
        v-model="remark1"
        style="grid-column: 1/-1;"
        rows="6"
        placeholder="上限420字"
      ></b-textarea>
    </header>

    <header class="header-row grid-2  mt-3 mb-3">
      <div class="grid-2-left" style="border-top:0px;padding-top:5px;">
        處理方式、建議事項或其他資源協助(必填)
      </div>
      <div></div>
      <b-textarea
        v-model="remark2"
        style="grid-column: 1/-1;"
        rows="6"
        placeholder="上限420字"
      ></b-textarea>
    </header>
    <div style="text-align: end;">
      <b-button class=" mr-2 btn-save" variant="success" @click="saveRecord"
        >儲存</b-button
      >
      <b-button
        class=" mr-2 "
        variant="danger"
        @click="printRecord"
        :disabled="!isAlreadyExist"
        >列印</b-button
      >
      <b-button class="" variant="info" @click="closeAll">取消</b-button>
    </div>
  </div>
</template>
<script>
import { store } from "@/store/global.js";
const allGrade = [
  { value: "1", text: "一" },
  { value: "2", text: "二" },
  { value: "3", text: "三" },
  { value: "4", text: "四" },
  { value: "5", text: "五" },
  { value: "6", text: "六" },
  { value: "7", text: "七" },
  { value: "8", text: "八" },
  { value: "9", text: "九" },
];

export default {
  data() {
    return {
      allGrade,
      telphone: "",
      tutorDate: null,
      tutorPlace: "",
      studentClass: "",
      studentId: "",

      Question_01: {
        q1: "N",
        q2: "N",
        q3: "N",
        q4: "N",
        q5: "N",
        q6: "N",
        q7: "N",
        q8: "N",
        q9: "N",
        event: "",
        other: "",
      },
      Question_021: {
        q1: "N",
        q2: "N",
        q3: "N",
        q4: "N",
        q5: "N",
        q6: "N",
        q7: "N",
        q8: "N",
        other: "",
      },
      Question_022: {
        q1: "N",
        q2: "N",
        q3: "N",
        q4: "N",
        q5: "N",
        other: "",
      },
      Question_023: {
        q1: "N",
        q2: "N",
        q3: "N",
        q4: "N",
        other: "",
      },
      Question_024: {
        q1: "N",
        q2: "N",
        q3: "N",
        q4: "N",
        other: "",
      },
      Question_025: {
        q1: "N",
        q2: "N",
        q3: "N",
        q4: "N",
        q5: "N",
        other: "",
      },
      Question_026: {
        q1: "N",
        q2: "N",
        q3: "N",
        q4: "N",
        other: "",
      },
      Question_027: {
        q1: "N",
        q2: "N",
        q3: "N",
        q4: "N",
        other: "",
        remark: "",
      },
      Question_028: {
        q1: "N",
        q2: "N",
        q3: "N",
        q4: "N",
        other: "",
        remark: "",
      },
      Question_029: {
        q1: "N",
        q2: "N",
        q3: "N",
        q4: "N",
        q5: "N",
        q6: "N",
        other: "",
        health: "",
      },
      Question_0210: {
        q1: "N",
        q2: "N",
        q3: "N",
        q4: "N",
        q5: "N",
        q6: "N",
        other: "",
        health: "",
      },
      remark1: "",
      remark2: "",

      allItems: {},
      chechAnswerErrorWord: "",
    };
  },

  props: ["record1Items", "tutor", "personinfo", "isAlreadyExist"],
  methods: {
    clearData(data, key) {
      if (data == "N") {
        switch (key) {
          case "Question_01Event":
            this.Question_01.event = "";
            break;
          case "Question_01Other":
            this.Question_01.other = "";
            break;
          case "Question_021Other":
            this.Question_021.other = "";
            break;
          case "Question_022Other":
            this.Question_022.other = "";
            break;
          case "Question_023Other":
            this.Question_023.other = "";
            break;
          case "Question_024Other":
            this.Question_024.other = "";
            break;
          case "Question_025Other":
            this.Question_025.other = "";
            break;
          case "Question_026Other":
            this.Question_026.other = "";
            break;
          case "Question_027Other":
            this.Question_027.other = "";
            break;
          case "Question_028Other":
            this.Question_028.other = "";
            break;
          case "Question_029Other":
            this.Question_029.other = "";
            break;
          case "Question_0210Other":
            this.Question_0210.other = "";
            break;
          case "Q27Remark":
            this.Question_027.remark = "";
            break;
          case "Q28Remark":
            this.Question_028.remark = "";
            break;
          case "Question_029Health":
            this.Question_029.health = "";
            break;
          case "Question_0210Health":
            this.Question_0210.health = "";
            break;
          //   break;
          default:
            console.log("No Data");
        }
      }
    },
    closeAll() {
      this.$emit("close");
    },
    setData() {
      let all = JSON.parse(JSON.stringify(this.allItems));

      this.telphone = all.Telphone;
      this.tutorDate = all.TutorDate
        ? this.$twDate(all.TutorDate)
        : this.$twDate(this.$moment(new Date()).format("YYYY-MM-DD"));
      this.tutorPlace = all.TutorPlace;
      this.studentClass = all.Class;
      this.studentId = all.StudentId
        ? all.StudentId
        : this.record1Items.record1StudentId
        ? this.record1Items.record1StudentId
        : "";
      this.remark1 = all.Remark1;
      this.remark2 = all.Remark2;

      const question01 = all.Question_01.split(",");

      for (let i = 0; i < question01.length; i += 1) {
        this.Question_01[`q${i + 1}`] = question01[i];
      }
      this.Question_01.event = all.Question_01_Event;
      this.Question_01.other = all.Question_01_Other;

      const question021 = all.Question_021.split(",");
      for (let i = 0; i < question021.length; i += 1) {
        this.Question_021[`q${i + 1}`] = question021[i];
      }
      this.Question_021.other = all.Question_021_Other;

      const question022 = all.Question_022.split(",");
      for (let i = 0; i < question022.length; i += 1) {
        this.Question_022[`q${i + 1}`] = question022[i];
      }
      this.Question_022.other = all.Question_022_Other;

      const question023 = all.Question_023.split(",");
      for (let i = 0; i < question023.length; i += 1) {
        this.Question_023[`q${i + 1}`] = question023[i];
      }
      this.Question_023.other = all.Question_023_Other;

      const question024 = all.Question_024.split(",");
      for (let i = 0; i < question024.length; i += 1) {
        this.Question_024[`q${i + 1}`] = question024[i];
      }
      this.Question_024.other = all.Question_024_Other;

      const question025 = all.Question_025.split(",");
      for (let i = 0; i < question025.length; i += 1) {
        this.Question_025[`q${i + 1}`] = question025[i];
      }
      this.Question_025.other = all.Question_025_Other;

      const question026 = all.Question_026.split(",");
      for (let i = 0; i < question026.length; i += 1) {
        this.Question_026[`q${i + 1}`] = question026[i];
      }
      this.Question_026.other = all.Question_026_Other;

      const question027 = all.Question_027.split(",");
      for (let i = 0; i < question027.length; i += 1) {
        this.Question_027[`q${i + 1}`] = question027[i];
      }
      this.Question_027.other = all.Question_027_Other;
      this.Question_027.remark = all.Question_027_Remark;

      const question028 = all.Question_028.split(",");
      for (let i = 0; i < question028.length; i += 1) {
        this.Question_028[`q${i + 1}`] = question028[i];
      }
      this.Question_028.other = all.Question_028_Other;
      this.Question_028.remark = all.Question_028_Remark;

      const question029 = all.Question_029.split(",");
      for (let i = 0; i < question029.length; i += 1) {
        this.Question_029[`q${i + 1}`] = question029[i];
      }
      this.Question_029.other = all.Question_029_Other;
      this.Question_029.health = all.Question_029_Health;

      const question0210 = all.Question_0210.split(",");
      for (let i = 0; i < question0210.length; i += 1) {
        this.Question_0210[`q${i + 1}`] = question0210[i];
      }
      this.Question_0210.other = all.Question_0210_Other;
      this.Question_0210.health = all.Question_0210_Health;
    },
    async saveRecord() {
      let obj = JSON.parse(JSON.stringify(this.allItems));
      obj.Telphone = this.telphone;
      obj.TutorDate = this.$usDate(this.tutorDate);
      obj.TutorPlace = this.tutorPlace;
      obj.Class = this.studentClass;
      obj.StudentId = this.studentId;

      obj.Question_01 = [
        this.Question_01.q1,
        this.Question_01.q2,
        this.Question_01.q3,
        this.Question_01.q4,
        this.Question_01.q5,
        this.Question_01.q6,
        this.Question_01.q7,
        this.Question_01.q8,
        this.Question_01.q9,
      ].toString();
      obj.Question_01_Event = this.Question_01.event;
      obj.Question_01_Other = this.Question_01.other;

      obj.Question_021 = [
        this.Question_021.q1,
        this.Question_021.q2,
        this.Question_021.q3,
        this.Question_021.q4,
        this.Question_021.q5,
        this.Question_021.q6,
        this.Question_021.q7,
        this.Question_021.q8,
      ].toString();
      obj.Question_021_Other = this.Question_021.other;

      obj.Question_022 = [
        this.Question_022.q1,
        this.Question_022.q2,
        this.Question_022.q3,
        this.Question_022.q4,
        this.Question_022.q5,
      ].toString();
      obj.Question_022_Other = this.Question_022.other;

      obj.Question_023 = [
        this.Question_023.q1,
        this.Question_023.q2,
        this.Question_023.q3,
        this.Question_023.q4,
      ].toString();
      obj.Question_023_Other = this.Question_023.other;

      obj.Question_024 = [
        this.Question_024.q1,
        this.Question_024.q2,
        this.Question_024.q3,
        this.Question_024.q4,
      ].toString();
      obj.Question_024_Other = this.Question_024.other;

      obj.Question_025 = [
        this.Question_025.q1,
        this.Question_025.q2,
        this.Question_025.q3,
        this.Question_025.q4,
        this.Question_025.q5,
      ].toString();
      obj.Question_025_Other = this.Question_025.other;

      obj.Question_026 = [
        this.Question_026.q1,
        this.Question_026.q2,
        this.Question_026.q3,
        this.Question_026.q4,
      ].toString();
      obj.Question_026_Other = this.Question_026.other;

      obj.Question_027 = [
        this.Question_027.q1,
        this.Question_027.q2,
        this.Question_027.q3,
        this.Question_027.q4,
      ].toString();
      obj.Question_027_Other = this.Question_027.other;
      obj.Question_027_Remark = this.Question_027.remark;

      obj.Question_028 = [
        this.Question_028.q1,
        this.Question_028.q2,
        this.Question_028.q3,
        this.Question_028.q4,
      ].toString();
      obj.Question_028_Other = this.Question_028.other;
      obj.Question_028_Remark = this.Question_028.remark;

      obj.Question_029 = [
        this.Question_029.q1,
        this.Question_029.q2,
        this.Question_029.q3,
        this.Question_029.q4,
        this.Question_029.q5,
        this.Question_029.q6,
      ].toString();
      obj.Question_029_Other = this.Question_029.other;
      obj.Question_029_Health = this.Question_029.health;

      obj.Question_0210 = [
        this.Question_0210.q1,
        this.Question_0210.q2,
        this.Question_0210.q3,
        this.Question_0210.q4,
        this.Question_0210.q5,
        this.Question_0210.q6,
      ].toString();
      obj.Question_0210_Other = this.Question_0210.other;
      obj.Question_0210_Health = this.Question_0210.health;

      obj.Remark1 = this.remark1;
      obj.Remark2 = this.remark2;

      const checkResult = this.chechAnswer(obj);
      if (!checkResult) {
        this.$bvToast.toast(this.chechAnswerErrorWord, {
          title: "系統資訊",
          autoHideDelay: 7000,
          variant: "danger",
        });
        this.chechAnswerErrorWord = "";
        return;
      }

      try {
        const url = `api/NurseRecord`;
        this.isAlreadyExist
          ? await window.axios.put(url, obj)
          : await window.axios.post(url, obj);
        // this.getData();

        this.$emit("updateModal");
      } catch (e) {
        this.$bvToast.toast(`${e.response ? e.response.data : e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    chechAnswer(obj) {
      let answer0 = obj.Question_01.includes("Y") ? true : false;
      let answer1 =
        this.countOccurences(obj.Question_021, "Y") == 1 ? true : false;
      let answer2 =
        this.countOccurences(obj.Question_022, "Y") == 1 ? true : false;
      let answer3 =
        this.countOccurences(obj.Question_023, "Y") == 1 ? true : false;
      let answer4 =
        this.countOccurences(obj.Question_024, "Y") == 1 ? true : false;
      let answer5 =
        this.countOccurences(obj.Question_025, "Y") == 1 ? true : false;
      let answer6 =
        this.countOccurences(obj.Question_026, "Y") == 1 ? true : false;
      let answer7 =
        this.countOccurences(obj.Question_027, "Y") == 1 ? true : false;
      let answer8 =
        this.countOccurences(obj.Question_028, "Y") == 1 ? true : false;
      let answer9 =
        this.countOccurences(obj.Question_029, "Y") == 1 ? true : false;
      let answer10 =
        this.countOccurences(obj.Question_0210, "Y") == 1 ? true : false;
      let remark1 = Boolean(obj.Remark1);
      let remark2 = Boolean(obj.Remark2);
      let all =
        answer0 &&
        answer1 &&
        answer2 &&
        answer3 &&
        answer4 &&
        answer5 &&
        answer6 &&
        answer7 &&
        answer8 &&
        answer9 &&
        answer10 &&
        remark1 &&
        remark2;
      this.chechAnswerErrorWord = `「第一大項輔導原因必填可複選」、「第二大項學生狀況評估答案皆為必填且單選」，請調整以下答案才可完成儲存: ${
        answer0 ? "" : "第一大項、"
      }${answer1 ? "" : "第1題、"}${answer2 ? "" : "第2題、"}${
        answer3 ? "" : "第3題、"
      }${answer4 ? "" : "第4題、"}${answer5 ? "" : "第5題、"}${
        answer6 ? "" : "第6題、"
      }${answer7 ? "" : "第7題、"}${answer8 ? "" : "第8題、"}${
        answer9 ? "" : "第9題、"
      }${answer10 ? "" : "第10題、"}${remark1 ? "" : "註解一、"}${
        remark2 ? "" : "註解二"
      }`;
      return all;
    },
    async printRecord() {
      const url = `/#/doc18`;
      // const url = `/#/doc18?schoolcode=${this.allItems.SchoolCode}&byear=${this.allItems.Ryear}&term=${this.allItems.Term}&tutor=${this.allItems.Tutor}&identifier=${this.allItems.Identifier}&Category=S`;
      const obj = {
        schoolcode: this.personinfo.SchoolCode,
        byear: this.personinfo.Ryear,
        term: this.personinfo.Term,
        category: "S",
        id: this.personinfo.Identifier,
        tutor: this.tutor,
      };
      sessionStorage.setItem("print", JSON.stringify(obj));
      window.open(url);
    },
    countOccurences(string, word) {
      if (!Boolean(string)) {
        return 0;
      } else {
        return string.split(word).length - 1;
      }
    },
  },
  watch: {},
  mounted() {
    if (this.record1Items && Boolean(this.record1Items.Identifier)) {
      this.allItems = JSON.parse(JSON.stringify(this.record1Items));
      this.setData();
    } else {
      this.allItems = {
        Class: "",
        Gender: this.personinfo.Gender,
        Grade: this.personinfo.Grade
          ? this.allGrade.find((s) => s.text == this.personinfo.Grade).value
          : "",
        Identifier: this.personinfo.Identifier,
        Question_01: "N,N,N,N,N,N,N,N,N",
        Question_01_Event: "",
        Question_01_Other: "",
        Question_021: "N,N,N,N,N,N,N,N",
        Question_021_Other: "",
        Question_022: "N,N,N,N,N",
        Question_022_Other: "",
        Question_023: "N,N,N,N",
        Question_023_Other: "",
        Question_024: "N,N,N,N",
        Question_024_Other: "",
        Question_025: "N,N,N,N,N",
        Question_025_Other: "",
        Question_026: "N,N,N,N",
        Question_026_Other: "",
        Question_027: "N,N,N,N",
        Question_027_Other: "",
        Question_027_Remark: "",
        Question_028: "N,N,N,N",
        Question_028_Other: "",
        Question_028_Remark: "",
        Question_029: "N,N,N,N,N,N",
        Question_029_Health: "",
        Question_029_Other: "",
        Question_0210: "N,N,N,N,N,N",
        Question_0210_Health: "",
        Question_0210_Other: "",
        Remark1: "",
        Remark2: "",
        Ryear: this.personinfo.Ryear,
        SchoolCode: this.personinfo.SchoolCode,
        // SeqNo: 49,
        StudentId: "",
        StudentName: this.personinfo.StudentName,
        Telphone: this.personinfo.Telphone,
        Term: this.personinfo.Term,
        Tutor: this.tutor,
        TutorDate: this.$moment(new Date()).format("YYYY-MM-DD"),
        TutorPlace: "",
      };
      this.setData();
    }
  },
};
</script>
<style lang="scss" scoped>
.my-item {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 425px);
  position: relative;
}
.input-area {
  border: 1px solid white;
  //   height: 40px;
  display: grid;
  grid-template-columns: 1fr;
}
.main-area {
  min-height: 100%;
  textarea {
    min-height: 100%;
  }
}
.my-note {
  height: 30px;
  font-size: 20px;
  background: var(--info);
  color: white;
  border-radius: 5px;
  padding-left: 10px;
}

.header-row {
  font-size: 18px;
  background: #c0c0c045;
  color: #333;
  border-radius: 3px;
  padding-left: 1px;
  padding-bottom: 10px;
  display: grid;
  grid-template-columns: repeat(24, max-content);
  grid-gap: 6px;
  border: #a4a7a7 1px solid;

  button {
    max-height: 35px;
    margin-top: 3px;
    line-height: 12px;
  }
}
.grid-2 {
  display: grid;
  grid-template-columns: 600px 422px;
  padding-left: 8px;
  .grid-content-2 {
    display: grid;
    grid-template-columns: max-content 185px;
    grid-column-gap: 7px;
  }
}
.grid-3 {
  display: grid;
  grid-template-columns: 340px 340px 340px;
  padding-left: 8px;
  .grid-content-2 {
    display: grid;
    grid-template-columns: max-content 185px;
    grid-column-gap: 7px;
  }
}
.question-font {
  font-weight: 549;
  grid-column: 1/-1;
  margin-top: 15px;
}
.basic-info {
  display: grid;
  grid-template-columns: 240px 270px 175px 175px 175px;
  grid-column-gap: 6px;
}
.one-row {
  grid-column: 1/-1;
}
</style>
