<template>
  <section class="dtc-main-section mt-2 mr-2">
    <section class="search-block">
      <h5 class="big-title">
        偏護菁英輔導紀錄
      </h5>
      <NurseRecordInput @update="getData"></NurseRecordInput>
      <SchoolRecordallMaintain></SchoolRecordallMaintain>
      <div class="dtc-search1">
        <b-input-group prepend="培育學校">
          <b-select
            :options="schoolOptions"
            v-model="searchSchool"
            value-field="SchoolCode"
            text-field="SchoolName"
            @change="getData"
            :disabled="healthCodeCheck"
          ></b-select>
        </b-input-group>
        <b-input-group prepend="年度">
          <b-select
            :options="yearsOption"
            v-model="searchYear"
            @change="getData"
          ></b-select>
        </b-input-group>
        <b-input-group prepend="學期">
          <b-select
            :options="[
              { value: '1', text: '一' },
              { value: '2', text: '二' },
            ]"
            v-model="searchTerm"
            @change="getData"
          ></b-select>
        </b-input-group>
        <b-input-group prepend="姓名">
          <b-input v-model="searchName" @keydown.enter="getData"></b-input>
        </b-input-group>
        <b-input-group prepend="身分證字號">
          <b-input v-model="searchId" @keydown.enter="getData"></b-input>
        </b-input-group>
        <b-button variant="primary" @click="getData">進行查詢</b-button>
        <b-button variant="danger" @click="clearSearch">清除條件</b-button>
        <b-button variant="info" @click="openRecordAll">總輔導記錄</b-button>
      </div>
    </section>

    <header class="dtc-grid-header my-dark">
      <div
        v-for="(item, i) in headersContent"
        :key="`headersContent${i}`"
        :title="item.name"
        @click="sort(item)"
      >
        {{ item.name }}
        <span v-show="item.sortDesc === null" v-if="item.key">
          <i class="fas fa-sort"></i>
        </span>
        <span v-show="item.sortDesc === false" v-if="item.key">
          <i class="fas fa-sort-up"></i>
        </span>
        <span v-show="item.sortDesc" v-if="item.key">
          <i class="fas fa-sort-down"></i>
        </span>
      </div>
    </header>
    <main
      class="dtc-grid-header2"
      v-for="(item, i) in items"
      :key="i"
      style="color:#39312E;"
      :style="
        i % 2 == 0 ? 'background-color:#ffffff ;' : 'background-color: #efefef;'
      "
    >
      <div>
        {{ i + 1 }}
      </div>
      <div style="padding:0 !important">
        <b-button variant="success" @click="editRecord(item)" size="sm"
          >編輯</b-button
        >
      </div>

      <div :title="item.Ryear">
        {{ item.Ryear || "" }}
      </div>
      <div :title="item.Term">
        {{ item.Term == "1" ? "一" : item.Term == "2" ? "二" : "" }}
      </div>
      <div :title="item.schoolName">
        {{ item.schoolName || "" }}
      </div>
      <div :title="item.StudentName">
        {{ item.StudentName || "" }}
      </div>
      <div :title="item.Identifier">
        {{ item.Identifier || "" }}
      </div>
      <div :title="item.Grade">
        {{ item.Grade || "" }}
      </div>
      <div :title="item.tutorComtent">
        {{ item.tutorComtent || "" }}
      </div>
    </main>
    <footer class="dtx-footer">
      <b-pagination
        v-model="currentPageNum"
        :total-rows="rowCount"
        :per-page="pagingRowPerPage"
        aria-controls="dataTable1"
        align="center"
        class="mt-2"
      >
        <span slot="first-text">
          <i class="fas fa-fast-backward"></i>
        </span>
        <span slot="prev-text">
          <i class="fas fa-step-backward"></i>
        </span>
        <span slot="next-text">
          <i class="fas fa-step-forward"></i>
        </span>
        <span slot="last-text">
          <i class="fas fa-fast-forward"></i>
        </span>
        <span slot="page" slot-scope="{ page, active }">
          <b v-if="active">{{ page }}</b>
          <i v-else>{{ page }}</i>
        </span>
      </b-pagination>
      <b-select
        class="mt-2"
        :options="rows"
        v-model="pagingRowPerPage"
      ></b-select>
      <div class="mt-3">{{ totalCountStr }}</div>
    </footer>
  </section>
</template>

<script>
const year = new Date().getFullYear() - 1911;
let yearsOption = new Array(8).fill().map((s, i) => year + 1 - i);
import buildQuery from "odata-query";
import { store } from "@/store/global.js";
import NurseRecordInput from "./NurseRecordInput";
import SchoolRecordallMaintain from "./SchoolRecordallMaintain";

const headersContent = [
  { name: "序", key: "", sortDesc: null },
  { name: "操作", key: "", sortDesc: null },
  { name: "年度", key: "Ryear", sortDesc: null },
  { name: "學期", key: "Term", sortDesc: null },
  { name: "培育學校", key: "SchoolCode", sortDesc: null },
  { name: "姓名", key: "StudentName", sortDesc: null },
  { name: "身分證字號", key: "Identifier", sortDesc: null },
  { name: "年級", key: "Grade", sortDesc: null },
  { name: "輔導紀錄", key: "", sortDesc: null },
];

const rows = [10, 20, 50];

export default {
  name: "downarea",
  data() {
    return {
      //main-content
      headersContent,
      items: [],

      //footer
      currentPageNum: 1,
      rowCount: 0,
      pagingRowPerPage: 20,
      orderBy: [],
      rows,
      totalCountStr: "",

      //modal
      modalItem: {
        Account: "",
        Name: "",
        Telphone: "",
        Mobile: "",
        Email: "",
        IsActive: true,
        isForever: false,
        LastActivityDate: null,
        RoleIds: [],
      },
      modalType: 1,

      //search
      searchSchool: null,
      searchYear: new Date().getFullYear() - 1911,
      searchTerm: "1",
      searchName: "",
      searchId: "",

      //searchOptions or ModalOptions
      schoolOptions: [],
      yearsOption,
    };
  },
  components: {
    NurseRecordInput,
    SchoolRecordallMaintain,
  },
  computed: {
    healthCodeCheck() {
      const loginHealthCode = sessionStorage
        .getItem("darcok")
        .replace(/\"/g, "");
      const schoolArr = JSON.parse(sessionStorage.getItem("stuChool"));
      return schoolArr.includes(loginHealthCode);
    },
  },

  methods: {
    clearSearch() {
      this.searchSchool = null;
      this.searchYear = new Date().getFullYear() - 1911;
      this.searchTerm = "1";
      this.searchName = "";
      this.searchId = "";
      this.getData();
    },
    sort(item) {
      if (!item.key) {
        return;
      }
      if (item.sortDesc) {
        item.sortDesc = null;
      } else if (false === item.sortDesc) {
        item.sortDesc = true;
      } else if (null === item.sortDesc) {
        item.sortDesc = false;
      }
      this.orderBy = [];

      this.headersContent.forEach((s) => {
        if (s.sortDesc !== null) {
          this.orderBy.push(s.sortDesc ? `${s.key} desc` : `${s.key}`);
        }
      });

      this.getData();
    },
    async editRecord(item) {
      store.editItem = { ...item };
      this.$bvModal.show("nurseRecordInput");
    },
    openRecordAll() {
      const schoolName = this.schoolOptions.find(
        (k) => k.SchoolCode == this.searchSchool
      )
        ? this.schoolOptions.find((k) => k.SchoolCode == this.searchSchool)
            .SchoolName
        : "";
      store.editRecord = {
        school: this.searchSchool,
        year: this.searchYear,
        term: this.searchTerm,
        schoolName,
      };
      const obj = { ...store.editRecord };
      sessionStorage.setItem("print", JSON.stringify(obj));
      this.$bvModal.show("schoolRecordallMaintain");
    },
    async getData() {
      if (this.healthCodeCheck) {
        this.searchSchool = sessionStorage.getItem("darcok").replace(/\"/g, "");
      }

      //odata3 qs
      const page = this.currentPageNum;
      const skip = (page - 1) * this.pagingRowPerPage;
      const top = this.pagingRowPerPage;
      const orderBy = this.orderBy;
      const obj = { top, skip, orderBy };
      let qs = buildQuery(obj);
      // let qs = ``;
      qs = `${qs}&Ryear=${this.searchYear}&Term=${this.searchTerm}`;
      let bQs = false;

      if (this.searchSchool) {
        qs = this.$equalFilter("SchoolCode", qs, this.searchSchool);
        bQs = true;
      }

      if (this.searchName) {
        if (bQs) {
          qs = this.$appendSubstringofFilter(
            "StudentName",
            qs,
            this.searchName
          );
        } else {
          qs = this.$equalSubstringofFilter("StudentName", qs, this.searchName);
        }
        bQs = true;
      }
      if (this.searchId) {
        if (bQs) {
          qs = this.$appendSubstringofFilter("Identifier", qs, this.searchId);
        } else {
          qs = this.$equalSubstringofFilter("Identifier", qs, this.searchId);
        }
        bQs = true;
      }

      try {
        let { Items, Count } = await window.axios.get(
          `NurseRecord/GetAllList${qs}`
        );

        Items = Items.map((s) => {
          s.termChinese = s.Term == "1" ? "一" : s.Term == "2" ? "二" : "";
          s.genderChinese =
            s.Gender == "M" ? "男" : s.Gender == "F" ? "女" : "";
          s.schoolName = this.schoolOptions.find(
            (k) => k.SchoolCode == s.SchoolCode
          )
            ? this.schoolOptions.find((k) => k.SchoolCode == s.SchoolCode)
                .SchoolName
            : "";
          s.tutorComtent = Boolean(s.Tutors)
            ? `${s.Tutors.includes("1") ? "■" : "□"}第一次 ${
                s.Tutors.includes("2") ? "■" : "□"
              }第二次 ${s.Tutors.includes("3") ? "■" : "□"}第三次 ${
                s.Tutors.includes("4") ? "■" : "□"
              }第四次 ${s.Tutors.includes("A") ? "■" : "□"}總輔導簡述`
            : `□第一次 □第二次 □第三次 □第四次 □總輔導簡述`;
          return s;
        });

        this.items = JSON.parse(JSON.stringify(Items));
        this.rowCount = Count;
        this.totalCountStr = `共${Count} 筆`;
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async getOptions() {
      let schoolOptions = JSON.parse(localStorage.dtcSchoolList);
      schoolOptions.unshift({ SchoolCode: null, SchoolName: "全部" });

      this.schoolOptions = JSON.parse(JSON.stringify(schoolOptions));
    },
  },
  async mounted() {
    await this.getOptions();
    await this.getData();
  },

  watch: {
    currentPageNum(v) {
      this.currentPageNum = v;
      this.getData().catch((e) => {});
    },
    pagingRowPerPage(v) {
      this.getData().catch((e) => {});
    },
  },
};
</script>

<style scoped lang="scss">
.dtc-main-section {
  padding: 12px;
  width: fit-content;
}
.dtc-grid-header,
.dtc-grid-header2 {
  display: grid;
  grid-template-columns: 120px 130px 130px 130px 260px 160px 160px 130px 450px;
  text-align: center;
  border-right: 0px;

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 15px;
    padding: 4px 0;
    padding-top: 5px !important;
    border-right: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    display: -webkit-box;
    height: 34px;
  }
  > div:first-child {
    border-left: 1px solid #cccccc;
  }
}
.dtc-grid-header {
  > div {
    line-height: 30px;
  }
}
.dtc-grid-header2 {
  > div {
    font-size: 16px;
    padding: 4px;
    // padding-top: 7px;
  }
}

.my-dark {
  > div {
    background: #333;
    color: white;
  }
}

.dtc-search,
.dtc-search1 {
  display: grid;
  max-height: 32px;
  margin-bottom: 12px;
  grid-template-columns: 296px max-content max-content 260px 260px repeat(
      6,
      max-content
    );
  grid-gap: 12px;
  padding-left: 10px;
  > * {
    max-height: 44px;
  }
}

.dtx-footer {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  grid-gap: 12px;
}

.input-group-text {
  width: 125px;
  background: #0379fd;
  color: #fff;
}

.search-block {
  //   height: 142px;
  margin-bottom: 10px;
  .big-title {
    background: #0379fd;
    padding: 5px;
    color: #fff;
    border-radius: 6px 6px 0 0;
  }

  background: #fbfcf9;
  border: 1px solid #bdbdbd;
  border-radius: 8px 8px 0 0;
}
::v-deep .modal-header {
  background: #7e2ca3;
  color: #ffffff;
  .close {
    color: #ffffff;
  }
}
.my-light {
  > div {
    background: black;
    color: black;
  }
}
.modal-grid-2 {
  display: grid;
  grid-template-columns: 353px 104px 1fr;
  grid-gap: 10px;
  .one-row {
    grid-column: 1/-1;
  }
  .two-column {
    grid-column: 2/-1;
  }
}
.flex-box {
  display: flex;
  flex-wrap: wrap;
  .item-box {
    margin: 5px;
  }
}
</style>
