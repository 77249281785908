<template>
  <b-modal
    @shown="updateItem"
    :title="
      `編輯個別輔導紀錄: ${personinfo.Ryear}年度 第${personinfo.termChinese}學期 ${personinfo.schoolName}`
    "
    id="nurseRecordInput"
    size="xl"
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
    scrollable
    @hide="doSometing"
  >
    <b-container fluid>
      <header class="header-grid">
        <b-input-group prepend="姓名">
          <b-input v-model="personinfo.StudentName" disabled></b-input>
        </b-input-group>
        <b-input-group prepend="性別">
          <b-input v-model="personinfo.genderChinese" disabled></b-input>
        </b-input-group>
        <b-input-group prepend="年級">
          <b-input v-model="personinfo.Grade" disabled></b-input>
        </b-input-group>
      </header>

      <b-input-group prepend="輔導次別">
        <b-button
          variant="outline-info"
          class="mr-2 ml-2"
          @click="handleTabChange(1)"
          :pressed="tabNow == 1"
          >第一次</b-button
        >
        <b-button
          variant="outline-info"
          class="mr-2"
          @click="handleTabChange(2)"
          :pressed="tabNow == 2"
          :disabled="!isOneExist"
          >第二次</b-button
        >
        <b-button
          variant="outline-info"
          class="mr-2"
          @click="handleTabChange(3)"
          :pressed="tabNow == 3"
          :disabled="!isTwoExist"
          >第三次</b-button
        >
        <b-button
          variant="outline-info"
          class="mr-2"
          @click="handleTabChange(4)"
          :pressed="tabNow == 4"
          :disabled="!isThreeExist"
          >第四次</b-button
        >
        <b-button
          variant="outline-info"
          @click="handleTabChange(5)"
          :pressed="tabNow == 'A'"
          :disabled="!isFourExist"
          >總輔導簡述</b-button
        >
      </b-input-group>
      <section>
        <keep-alive>
          <RecordInput1
            @close="closeModal"
            @updateModal="updateAll"
            v-if="tabNow == 1"
            :record1Items="record1Items"
            :personinfo="personinfo"
            :key="`record1Items${compontKey}`"
            tutor="1"
            :isAlreadyExist="isOneExist"
          ></RecordInput1>
          <RecordInput1
            @close="closeModal"
            @updateModal="updateAll"
            v-if="tabNow == 2"
            :record1Items="record2Items"
            :personinfo="personinfo"
            :key="`record2Items${compontKey}`"
            tutor="2"
            :isAlreadyExist="isTwoExist"
          ></RecordInput1>
          <RecordInput1
            @close="closeModal"
            @updateModal="updateAll"
            v-if="tabNow == 3"
            :record1Items="record3Items"
            :personinfo="personinfo"
            :key="`record3Items${compontKey}`"
            tutor="3"
            :isAlreadyExist="isThreeExist"
          ></RecordInput1>
          <RecordInput1
            @close="closeModal"
            @updateModal="updateAll"
            v-if="tabNow == 4"
            :record1Items="record4Items"
            :personinfo="personinfo"
            :key="`record4Items${compontKey}`"
            tutor="4"
            :isAlreadyExist="isFourExist"
          ></RecordInput1>
          <RecordLastInput
            @close="closeModal"
            @updateModal2="updateAll2"
            v-if="tabNow == 'A'"
            :recordAItems="recordAItems"
            :personinfo="personinfo"
            :key="`recordAItems${compontKey}`"
            :isAlreadyExist="isTotalExist"
          ></RecordLastInput>
        </keep-alive>
      </section>
    </b-container>

    <template v-slot:modal-footer>
      <div class="w-100">
        <b-button
          variant="info"
          class="float-right ml-3 mr-3"
          @click="$bvModal.hide('nurseRecordInput')"
          >關閉</b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
import { store } from "@/store/global.js";
import buildQuery from "odata-query";
import RecordInput1 from "./RecordInput1";
import RecordLastInput from "./RecordLastInput";

import { VueTabs, VTab } from "vue-nav-tabs";
import "vue-nav-tabs/themes/vue-tabs.css";
export default {
  name: "NurseRecordInput",
  data() {
    return {
      personinfo: {},
      variants: [
        "warning",
        "dark",
        "light",
        "warning",
        "danger",
        "info",
        "light",
        "dark",
      ],

      items: [],
      record1Items: {},
      record2Items: {},
      record3Items: {},
      record4Items: {},
      recordAItems: {},

      compontKey: 0,
      tabNow: 1,

      isOneExist: false,
      isTwoExist: false,
      isThreeExist: false,
      isFourExist: false,
      isTotalExist: false,
    };
  },

  components: {
    RecordInput1,
    RecordLastInput,
    VueTabs,
    VTab,
  },
  computed: {},
  methods: {
    doSometing() {
      // this.$emit("update");
      console.log("");
    },
    async closeModal() {
      this.$bvModal.hide("nurseRecordInput");
    },
    async updateAll() {
      // await this.checkIsExist2();
      // await this.getData();
      this.$bvModal.hide("nurseRecordInput");
      this.$emit("update");
      this.$bvToast.toast(`輔導記錄儲存成功`, {
        title: "系統資訊",
        autoHideDelay: 5000,
        variant: "success",
      });
    },
    async updateAll2() {
      this.$bvModal.hide("nurseRecordInput");
      this.$emit("update");
      this.$bvToast.toast(`總輔導簡述儲存成功`, {
        title: "系統資訊",
        autoHideDelay: 5000,
        variant: "success",
      });
    },
    handleTabChange(num) {
      switch (num) {
        case 1:
          this.tabNow = 1;
          break;
        case 2:
          this.tabNow = 2;
          break;
        case 3:
          this.tabNow = 3;
          break;
        case 4:
          this.tabNow = 4;
          break;
        case 5:
          this.tabNow = "A";
          break;
        default:
          console.log("No Data");
      }
    },
    async updateItem() {
      this.isOneExist = false;
      this.isTwoExist = false;
      this.isThreeExist = false;
      this.isFourExist = false;
      this.isTotalExist = false;
      this.personinfo = JSON.parse(JSON.stringify(store.editItem));

      this.tabNow = 1;
      await this.checkIsExist();
      await this.getData();
    },
    async checkIsExist() {
      const arr = this.personinfo.Tutors;
      if (!Boolean(arr)) {
        this.isOneExist = false;
        this.isTwoExist = false;
        this.isThreeExist = false;
        this.isFourExist = false;
        this.isTotalExist = false;
      } else {
        if (arr.includes("1")) {
          this.isOneExist = true;
          this.tabNow = 2;
        }
        if (arr.includes("2")) {
          this.isTwoExist = true;
          this.tabNow = 3;
        }
        if (arr.includes("3")) {
          this.isThreeExist = true;
          this.tabNow = 4;
        }
        if (arr.includes("4")) {
          this.isFourExist = true;
          this.tabNow = "A";
        }
        if (arr.includes("A")) {
          this.isTotalExist = true;
        }
      }
    },
    async checkIsExist2() {
      //for save re-get Data
      let qs = `?Ryear=${this.personinfo.Ryear}&Term=${this.personinfo.Term}&$filter=Identifier eq '${this.personinfo.Identifier}'`;
      let { Items } = await window.axios.get(`NurseRecord/GetAllList${qs}`);
      let obj = JSON.parse(JSON.stringify(Items[0]));
      let arr = obj.Tutors;
      if (!Boolean(arr)) {
        this.isOneExist = false;
        this.isTwoExist = false;
        this.isThreeExist = false;
        this.isFourExist = false;
        this.isTotalExist = false;
      } else {
        if (arr.includes("1")) {
          this.isOneExist = true;
          this.tabNow = 2;
        }
        if (arr.includes("2")) {
          this.isTwoExist = true;
          this.tabNow = 3;
        }
        if (arr.includes("3")) {
          this.isThreeExist = true;
          this.tabNow = 4;
        }
        if (arr.includes("4")) {
          this.isFourExist = true;
          this.tabNow = "A";
        }
        if (arr.includes("A")) {
          this.isTotalExist = true;
        }
      }
    },
    async getData() {
      let qs = `?$filter=SchoolCode eq '${this.personinfo.SchoolCode}'`;
      qs = this.$appendSubstringofFilter("Ryear", qs, this.personinfo.Ryear);
      qs = this.$appendSubstringofFilter("Term", qs, this.personinfo.Term);
      qs = this.$appendSubstringofFilter(
        "Identifier",
        qs,
        this.personinfo.Identifier
      );

      const url = `api/NurseRecord${qs}`;
      const urlAll = `RecordAll/GetSingle?schoolcode=${this.personinfo.SchoolCode}&byear=${this.personinfo.Ryear}&term=${this.personinfo.Term}&identifier=${this.personinfo.Identifier}`;
      try {
        const { Items } = await window.axios.get(url);
        const resAll = await window.axios.get(urlAll);

        const record1 = Items.find((s) => s.Tutor == "1")
          ? Items.find((s) => s.Tutor == "1")
          : {};
        let record2 = Items.find((s) => s.Tutor == "2")
          ? Items.find((s) => s.Tutor == "2")
          : {};
        let record3 = Items.find((s) => s.Tutor == "3")
          ? Items.find((s) => s.Tutor == "3")
          : {};
        let record4 = Items.find((s) => s.Tutor == "4")
          ? Items.find((s) => s.Tutor == "4")
          : {};

        // console.log(record1, record2);
        record2.record1StudentId = record1.StudentId;
        record3.record1StudentId = record1.StudentId;
        record4.record1StudentId = record1.StudentId;
        const recordAll = Boolean(resAll) ? resAll : {};

        this.record1Items = JSON.parse(JSON.stringify(record1));
        this.record2Items = JSON.parse(JSON.stringify(record2));
        this.record3Items = JSON.parse(JSON.stringify(record3));
        this.record4Items = JSON.parse(JSON.stringify(record4));
        this.recordAItems = JSON.parse(JSON.stringify(resAll));
        this.items = JSON.parse(JSON.stringify(recordAll));

        this.compontKey += 1;
      } catch (e) {
        console.log(e);
      }
    },
  },

  mounted() {},
  watch: {},
};
</script>

<style scoped lang="scss">
.dtc-grid-header,
.dtc-grid-header2 {
  display: grid;
  grid-template-columns: 140px 140px 140px 1fr 120px 120px;
  grid-auto-flow: column;
  text-align: center;
  border-right: 0px;

  > div {
    // word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    word-break: keep-all;
    -webkit-box-orient: vertical;
    font-size: 14px;
    padding: 4px 0;
    border-top: 1px solid #dee2e5;
    border-right: 1px solid #dee2e5;
    border-bottom: 1px solid #dee2e5;
  }
  > div:first-child {
    border-left: 1px solid #dee2e5;
  }
  > div:last-child {
    // border-right: none;
  }
}
.dtc-grid-header2 {
  > div {
    font-size: 16px;
    padding: 0;
    line-height: 46px;
    height: 46px;
  }
  .fee {
    text-align: right;
    padding-right: 10px;
  }
}
p {
  font-size: 2em;
  text-align: center;
}
.input-group-text {
  width: 100px;
  background: #0379fd;
  color: #fff;
}

div.input-group.special-col > div.input-group-prepend > div.input-group-text {
  width: 130px;
}
div.input-group.dtc-bg > div.input-group-prepend > div.input-group-text {
  background: #646969;
  color: #ffffff;
}

.input-group {
  margin-bottom: 10px;
}

::v-deep #NurseRecordInput > .modal-dialog > .modal-content > .modal-header {
  background: #7e2ca3;
  color: #ffffff;
  .close {
    color: #ffffff;
  }
}
// ::v-deep .modal-content {
//   margin-left: -180px;
//   width: 136%;
// }
.form-control[readonly] {
  background-color: #ffffff;
}
hr.new {
  border-top: 1px dashed #cacaca;
}
h4.title {
  font-size: 20px;
  font-weight: 700;
}
.dtx-footer {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  grid-gap: 12px;
}
::v-deep .modal-header {
  background: #7e2ca3;
  color: #ffffff;
  .close {
    color: #ffffff;
  }
}
.header-grid {
  display: grid;

  grid-template-columns: repeat(7, 210px);
  grid-gap: 6px;
}
::v-deep .modal-footer {
  display: none;
}
</style>
